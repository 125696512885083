@import '../../@framework/components/OptionButton/OptionButton.less';
.optionbutton-global-variables() {
                  .global-variables();
                  @FontWeight: normal;
  @MarginBottom: 7px;
  @MarginLeft: 7px;
  @MarginRight: 7px;
  @MarginTop: 7px;
  @Scale: 1.3;

                }

                .zapp-optionbutton  {
                  .optionbutton-global-variables();
                  
                    @BorderColor: @color-default;
  @FontWeight: normal;
  @MarginBottom: 7px;
  @MarginLeft: 7px;
  @MarginRight: 7px;
  @MarginTop: 7px;
  @Scale: 1.3;

                  
                  .optionbutton-status-mixin();
}

.optionbutton-status-default {
  .global-variables();
  @BorderColor: @color-default;
  @FontWeight: normal;
  @MarginBottom: 7px;
  @MarginLeft: 7px;
  @MarginRight: 7px;
  @MarginTop: 7px;
  @Scale: 1.3;



  .optionbutton-status-mixin();
}

.optionbutton-status-primary {
  .global-variables();
  @BorderColor: @color-primary;
  @FontWeight: normal;
  @MarginBottom: 7px;
  @MarginLeft: 7px;
  @MarginRight: 7px;
  @MarginTop: 7px;
  @Scale: 1.3;



  .optionbutton-status-mixin();
}

.optionbutton-status-info {
  .global-variables();
  @BorderColor: @color-info;
  @FontWeight: normal;
  @MarginBottom: 7px;
  @MarginLeft: 7px;
  @MarginRight: 7px;
  @MarginTop: 7px;
  @Scale: 1.3;



  .optionbutton-status-mixin();
}

.optionbutton-status-success {
  .global-variables();
  @BorderColor: @color-success;
  @FontWeight: normal;
  @MarginBottom: 7px;
  @MarginLeft: 7px;
  @MarginRight: 7px;
  @MarginTop: 7px;
  @Scale: 1.3;



  .optionbutton-status-mixin();
}

.optionbutton-status-warning {
  .global-variables();
  @BorderColor: @color-warning;
  @FontWeight: normal;
  @MarginBottom: 7px;
  @MarginLeft: 7px;
  @MarginRight: 7px;
  @MarginTop: 7px;
  @Scale: 1.3;



  .optionbutton-status-mixin();
}

.optionbutton-status-danger {
  .global-variables();
  @BorderColor: @color-danger;
  @FontWeight: normal;
  @MarginBottom: 7px;
  @MarginLeft: 7px;
  @MarginRight: 7px;
  @MarginTop: 7px;
  @Scale: 1.3;



  .optionbutton-status-mixin();
}

.optionbutton-size-xs {
  .global-variables();
  @FontSize: @font-xs;
  @FontWeight: normal;
  @MarginBottom: 3px;
  @MarginLeft: 3px;
  @MarginRight: 3px;
  @MarginTop: 3px;
  @Scale: 0.8;



  .optionbutton-size-mixin();
}

.optionbutton-size-sm {
  .global-variables();
  @FontSize: @font-sm;
  @FontWeight: normal;
  @MarginBottom: 7px;
  @MarginLeft: 7px;
  @MarginRight: 7px;
  @MarginTop: 7px;
  @Scale: 1;



  .optionbutton-size-mixin();
}

.optionbutton-size-med {
  .global-variables();
  @FontSize: @font-med;
  @FontWeight: normal;
  @MarginBottom: 7px;
  @MarginLeft: 7px;
  @MarginRight: 7px;
  @MarginTop: 7px;
  @Scale: 1.3;



  .optionbutton-size-mixin();
}

.optionbutton-size-lg {
  .global-variables();
  @FontSize: @font-lg;
  @FontWeight: normal;
  @MarginBottom: 10px;
  @MarginLeft: 10px;
  @MarginRight: 10px;
  @MarginTop: 10px;
  @Scale: 2;



  .optionbutton-size-mixin();
}

.optionbutton-size-xl {
  .global-variables();
  @FontSize: @font-xl;
  @FontWeight: normal;
  @MarginBottom: 20px;
  @MarginLeft: 20px;
  @MarginRight: 20px;
  @MarginTop: 20px;
  @Scale: 3;



  .optionbutton-size-mixin();
}



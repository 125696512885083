@import '../../@framework/components/Table/table.component.less';
.table-global-variables() {
                  .global-variables();
                  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;

                }

                .zapp-table  {
                  .table-global-variables();
                  
                  
                  
                  .table-status-mixin();
}

.table-status-default {
  .global-variables();
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;



  .table-status-mixin();
}

.table-status-primary {
  .global-variables();
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;



  .table-status-mixin();
}

.table-status-info {
  .global-variables();
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;



  .table-status-mixin();
}

.table-status-success {
  .global-variables();
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;



  .table-status-mixin();
}

.table-status-warning {
  .global-variables();
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;



  .table-status-mixin();
}

.table-status-danger {
  .global-variables();
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;



  .table-status-mixin();
}







.theader-global-variables() {
                  .global-variables();
                  @BackgroundColor: @header-bg;
  @ForegroundColor: @header-color;
  @FontWeight: normal;

                }

                .zapp-theader  {
                  .theader-global-variables();
                  
                  
                  
                  .theader-status-mixin();
}

.theader-status-default {
  .global-variables();
  @BackgroundColor: @color-default;
  @BorderBottomColor: @color-default;
  @BorderLeftColor: @color-default;
  @BorderRightColor: @color-default;
  @BorderTopColor: @color-default;
  @ForegroundColor: @color-default-contrast;
  @FontWeight: normal;



  .theader-status-mixin();
}

.theader-status-primary {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderBottomColor: @color-primary;
  @BorderLeftColor: @color-primary;
  @BorderRightColor: @color-primary;
  @BorderTopColor: @color-primary;
  @ForegroundColor: @color-primary-contrast;
  @FontWeight: normal;



  .theader-status-mixin();
}

.theader-status-info {
  .global-variables();
  @BackgroundColor: @color-info;
  @BorderBottomColor: @color-info;
  @BorderLeftColor: @color-info;
  @BorderRightColor: @color-info;
  @BorderTopColor: @color-info;
  @ForegroundColor: @color-info-contrast;
  @FontWeight: normal;



  .theader-status-mixin();
}

.theader-status-success {
  .global-variables();
  @BackgroundColor: @color-success;
  @BorderBottomColor: @color-success;
  @BorderLeftColor: @color-success;
  @BorderRightColor: @color-success;
  @BorderTopColor: @color-success;
  @ForegroundColor: @color-success-contrast;
  @FontWeight: normal;



  .theader-status-mixin();
}

.theader-status-warning {
  .global-variables();
  @BackgroundColor: @color-warning;
  @BorderBottomColor: @color-warning;
  @BorderLeftColor: @color-warning;
  @BorderRightColor: @color-warning;
  @BorderTopColor: @color-warning;
  @ForegroundColor: @color-warning-contrast;
  @FontWeight: normal;



  .theader-status-mixin();
}

.theader-status-danger {
  .global-variables();
  @BackgroundColor: @color-danger;
  @BorderBottomColor: @color-danger;
  @BorderLeftColor: @color-danger;
  @BorderRightColor: @color-danger;
  @BorderTopColor: @color-danger;
  @ForegroundColor: @color-danger-contrast;
  @FontWeight: normal;



  .theader-status-mixin();
}







.tbody-global-variables() {
                  .global-variables();
                  @BackgroundColor: transparent;

                }

                .zapp-tbody  {
                  .tbody-global-variables();
                  
                  
                  
                  .tbody-status-mixin();
}

.tbody-status-default {
  .global-variables();
  @BackgroundColor: transparent;



  .tbody-status-mixin();
}

.tbody-status-primary {
  .global-variables();
  @BackgroundColor: transparent;



  .tbody-status-mixin();
}

.tbody-status-info {
  .global-variables();
  @BackgroundColor: transparent;



  .tbody-status-mixin();
}

.tbody-status-success {
  .global-variables();
  @BackgroundColor: transparent;



  .tbody-status-mixin();
}

.tbody-status-warning {
  .global-variables();
  @BackgroundColor: transparent;



  .tbody-status-mixin();
}

.tbody-status-danger {
  .global-variables();
  @BackgroundColor: transparent;



  .tbody-status-mixin();
}







.tfooter-global-variables() {
                  .global-variables();
                  @BackgroundColor: @footer-bg;
  @ForegroundColor: @footer-color;

                }

                .zapp-tfooter  {
                  .tfooter-global-variables();
                  
                  
                  
                  .tfooter-status-mixin();
}

.tfooter-status-default {
  .global-variables();
  @BackgroundColor: @color-default;
  @BorderBottomColor: @color-default;
  @BorderLeftColor: @color-default;
  @BorderRightColor: @color-default;
  @BorderTopColor: @color-default;
  @ForegroundColor: @color-default-contrast;



  .tfooter-status-mixin();
}

.tfooter-status-primary {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderBottomColor: @color-primary;
  @BorderLeftColor: @color-primary;
  @BorderRightColor: @color-primary;
  @BorderTopColor: @color-primary;
  @ForegroundColor: @color-primary-contrast;



  .tfooter-status-mixin();
}

.tfooter-status-info {
  .global-variables();
  @BackgroundColor: @color-info;
  @BorderBottomColor: @color-info;
  @BorderLeftColor: @color-info;
  @BorderRightColor: @color-info;
  @BorderTopColor: @color-info;
  @ForegroundColor: @color-info-contrast;



  .tfooter-status-mixin();
}

.tfooter-status-success {
  .global-variables();
  @BackgroundColor: @color-success;
  @BorderBottomColor: @color-success;
  @BorderLeftColor: @color-success;
  @BorderRightColor: @color-success;
  @BorderTopColor: @color-success;
  @ForegroundColor: @color-success-contrast;



  .tfooter-status-mixin();
}

.tfooter-status-warning {
  .global-variables();
  @BackgroundColor: @color-warning;
  @BorderBottomColor: @color-warning;
  @BorderLeftColor: @color-warning;
  @BorderRightColor: @color-warning;
  @BorderTopColor: @color-warning;
  @ForegroundColor: @color-warning-contrast;



  .tfooter-status-mixin();
}

.tfooter-status-danger {
  .global-variables();
  @BackgroundColor: @color-danger;
  @BorderBottomColor: @color-danger;
  @BorderLeftColor: @color-danger;
  @BorderRightColor: @color-danger;
  @BorderTopColor: @color-danger;
  @ForegroundColor: @color-danger-contrast;



  .tfooter-status-mixin();
}







.row-global-variables() {
                  .global-variables();
                
                }

                .zapp-row  {
                  .row-global-variables();
                  
                  
                  
                  .row-status-mixin();
}













.cell-global-variables() {
                  .global-variables();
                  @PaddingBottom: 3px;
  @PaddingLeft: 6px;
  @PaddingRight: 6px;
  @PaddingTop: 3px;

                }

                .zapp-cell  {
                  .cell-global-variables();
                  
                  
                  
                  .cell-status-mixin();
}














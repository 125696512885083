@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

.formGroup-mixin () {
    display: block;
  .background-color(@BackgroundColor);
  .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
  .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
  .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
  .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
}

.formgroup-status-mixin() {
  .formGroup-mixin();
}

.formgrouplabelcontainer-status-mixin() {
  .background-color(@BackgroundColor);
  .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
  .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
  .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
  .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
  .min-width(@MinWidth);

  > .zapp-label, > label {
    .font(@FontDecoration, @FontFamily, @FontStyle, @FontSize, @FontWeight);
  }
}

.formgroupinputcontainer-status-mixin() {
  .background-color(@BackgroundColor);
  .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
  .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
  .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
  .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
  .font(@FontDecoration, @FontFamily, @FontStyle, @FontSize, @FontWeight);
}

.formgroupdescriptioncontainer-status-mixin () {
  .background-color(@BackgroundColor);
  .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
  .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
  .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
  .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);

  > .zapp-label, > label {
    .font(@FontDecoration, @FontFamily, @FontStyle, @FontSize, @FontWeight);
  }
}

.horizontal{
    display: grid;
    grid-template-columns: fit-content(30%) auto;
    grid-template-rows: auto;
    grid-column-gap: 20px;
    .form-group-label{
        grid-column: ~"1 / 2";
        grid-row-start: 1;
        /*justify-self: end;*/
    }

    .form-group-input{
        grid-column: ~"2 / 3";
        grid-row-start: 1;
    }
    .form-group-description{
        grid-column: ~"2 / 3";
        grid-row-start: 2;
    }
}

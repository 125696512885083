@import '../../@framework/components/FormGroup/FormGroup.component.less';
.formgroup-global-variables() {
                  .global-variables();
                
                }

                .zapp-formgroup  {
                  .formgroup-global-variables();
                  
                  
                  
                  .formgroup-status-mixin();
}













.formgrouplabelcontainer-global-variables() {
                  .global-variables();
                
                }

                .zapp-formgrouplabelcontainer  {
                  .formgrouplabelcontainer-global-variables();
                  
                  
                  
                  .formgrouplabelcontainer-status-mixin();
}













.formgroupinputcontainer-global-variables() {
                  .global-variables();
                
                }

                .zapp-formgroupinputcontainer  {
                  .formgroupinputcontainer-global-variables();
                  
                  
                  
                  .formgroupinputcontainer-status-mixin();
}













.formgroupdescriptioncontainer-global-variables() {
                  .global-variables();
                  @FontSize: @font-sm;

                }

                .zapp-formgroupdescriptioncontainer  {
                  .formgroupdescriptioncontainer-global-variables();
                  
                  
                  
                  .formgroupdescriptioncontainer-status-mixin();
}














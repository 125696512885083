@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';

.table-status-mixin() {
  .font-mixin();

  background-color: @BackgroundColor;
}

.row-status-mixin() {

}

.cell-status-mixin() {

}

.theader-status-mixin() {

}

.tbody-status-mixin() {

}

.tfooter-status-mixin() {

}

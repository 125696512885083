@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

.Layout-status-mixin() {
  display: block;

  > div {
    .background-color(@BackgroundColor);
    .color(@ForegroundColor);

    .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
    .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);


    .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
    .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);

    .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);

    .font-size(@FontSize);
    .shadow(@Shadow);
  }
}

.Row-status-mixin() {
  .background-color(@BackgroundColor);
  .color(@ForegroundColor);

  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
  .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);


  .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
  .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
  .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);

  .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);

  .font-size(@FontSize);
  .shadow(@Shadow);
}

.Column-status-mixin() {
  .background-color(@BackgroundColor);
  .color(@ForegroundColor);

  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
  .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);

  .font-size(@FontSize);
}


.is-dimension(@var, @ruleset) when (isunit(@var, px)), (isunit(@var, %)), (isunit(@var, vh)), (isunit(@var, vw)), (isunit(@var, em)), (isunit(@var, ex)), (isunit(@var, cm)), (isunit(@var, mm)), (isunit(@var, in)), (isunit(@var, pt)), (isunit(@var, pc)), (isunit(@var, ch)), (isunit(@var, rem)), (isunit(@var, vmin)), (isunit(@var, vmax)) {
  @ruleset();
}

.is-color(@var, @ruleset) when (iscolor(@var)) {
  @ruleset();
}

.is-keyword(@var, @ruleset) when (iskeyword(@var)) {
  @ruleset();
}

.is-number(@var, @ruleset) when (isnumber(@var)) {
  @ruleset();
}

.is-number-or-keyword(@var, @ruleset) when (isnumber(@var)), (iskeyword(@var)) {
  @ruleset();
}

/* dimensions */
.width(@var) {
  .is-dimension(@var, { width: @var; });
}
.min-width(@var) {
  .is-dimension(@var, { min-width: @var; });
}
.height(@var) {
  .is-dimension(@var, { height: @var; });
}
.min-height(@var) {
  .is-dimension(@var, { min-height: @var; });
}
.line-height(@var) {
  .is-dimension(@var, { line-height: @var; });
}
.line-height-double(@var) {
  .is-dimension(@var, { line-height: calc(2 * @var); });
}

/* misc */
.shadow(@var) {
  //TODO: implement
}
.opacity(@var) {
  .is-number(@var, { opacity: @var; });
}

/* paddings */
.padding-top(@var) {
  .is-dimension(@var, { padding-top: @var; });
}
.padding-right(@var) {
  .is-dimension(@var, { padding-right: @var; });
}
.padding-bottom(@var) {
  .is-dimension(@var, { padding-bottom: @var; });
}
.padding-left(@var) {
  .is-dimension(@var, { padding-left: @var; });
}

/* margins */
.margin-top(@var) {
  .is-dimension(@var, { margin-top: @var; });
}
.margin-right(@var) {
  .is-dimension(@var, { margin-right: @var; });
}
.margin-bottom(@var) {
  .is-dimension(@var, { margin-bottom: @var; });
}
.margin-left(@var) {
  .is-dimension(@var, { margin-left: @var; });
}

/* border radius */
.border-top-left-radius(@var) {
    .is-dimension(@var, { border-top-left-radius: @var; });
}
.border-top-right-radius(@var) {
    .is-dimension(@var, { border-top-right-radius: @var; });
}
.border-bottom-left-radius(@var) {
    .is-dimension(@var, { border-bottom-left-radius: @var; });
}
.border-bottom-right-radius(@var) {
    .is-dimension(@var, { border-bottom-right-radius: @var; });
}

/* border width */
.border-top-width(@var) {
    .is-dimension(@var, { border-top-width: @var; });
}
.border-right-width(@var) {
    .is-dimension(@var, { border-right-width: @var; });
}
.border-bottom-width(@var) {
    .is-dimension(@var, { border-bottom-width: @var; });
}
.border-left-width(@var) {
    .is-dimension(@var, { border-left-width: @var; });
}

/* font */
.font-size(@var) {
    .is-dimension(@var, { font-size: @var; });
}
.font-weight(@var) {
    .is-number-or-keyword(@var, { font-weight: @var; });
}
.text-decoration(@var) {
    .is-keyword(@var, { text-decoration: @var; });
}
.font-family(@var) {
    .is-keyword(@var, { font-family: @var; });
}
.font-style(@var) {
    .is-keyword(@var, { font-style: @var; });
}

/* colors */
.background-color(@var) {
    .is-color(@var, { background-color: @var; });
}
.background-color-important(@var) {
    .is-color(@var, { background-color: @var!important; });
}
.color(@var) {
    .is-color(@var, { color: @var; });
}
.color-important(@var) {
    .is-color(@var, { color: @var!important; });
}
.text-decoration-color(@var) {
    .is-color(@var, { text-decoration-color: @var!important; });
}

/* border colors */
.border-top-color(@var) {
    .is-color(@var, { border-top-color: @var; });
}
.border-right-color(@var) {
    .is-color(@var, { border-right-color: @var; });
}
.border-bottom-color(@var) {
    .is-color(@var, { border-bottom-color: @var; });
}
.border-left-color(@var) {
    .is-color(@var, { border-left-color: @var; });
}
.border-color(@var) {
    .is-color(@var, { border-color: @var; });
}

/* border styles */
.border-top-style(@var) {
    .is-keyword(@var, { border-top-style: @var; });
}
.border-right-style(@var) {
    .is-keyword(@var, { border-right-style: @var; });
}
.border-bottom-style(@var) {
    .is-keyword(@var, { border-bottom-style: @var; });
}
.border-left-style(@var) {
    .is-keyword(@var, { border-left-style: @var; });
}


@import '../../@framework/components/RichTextBox/RichTextBox.component.less';
.richtextbox-global-variables() {
                  .global-variables();
                  @BackgroundColor: @input-bg;
  @BorderBottomColor: @input-border-color;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @input-border-color;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @input-border-color;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @input-border-color;
  @BorderTopWidth: @input-border-width;
  @FontSize: @input-font-size;
  @ForegroundColor: @input-color;
  @Height: @input-height;
  @PaddingBottom: @input-padding-v;
  @PaddingLeft: @input-padding-v;
  @PaddingRight: @input-padding-v;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @Width: 100%;

                }

                .zapp-richtextbox  {
                  .richtextbox-global-variables();
                  
                  
                  
                  .richtextbox-status-mixin();
}

.richtextbox-status-default {
  .global-variables();
  @BackgroundColor: @color-default;
  @BorderBottomColor: @color-default;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @color-default;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-default;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @color-default;
  @BorderTopWidth: @input-border-width;
  @FontSize: @input-font-size;
  @ForegroundColor: @color-default-contrast;
  @Height: @input-height;
  @PaddingBottom: @input-padding-v;
  @PaddingLeft: @input-padding-v;
  @PaddingRight: @input-padding-v;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @Width: 100%;



  .richtextbox-status-mixin();
}

.richtextbox-status-primary {
  .global-variables();
  @BackgroundColor: @color-primary;
  @BorderBottomColor: @color-primary;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @color-primary;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-primary;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @color-primary;
  @BorderTopWidth: @input-border-width;
  @FontSize: @input-font-size;
  @ForegroundColor: @color-primary-contrast;
  @Height: @input-height;
  @PaddingBottom: @input-padding-v;
  @PaddingLeft: @input-padding-v;
  @PaddingRight: @input-padding-v;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @Width: 100%;



  .richtextbox-status-mixin();
}

.richtextbox-status-info {
  .global-variables();
  @BackgroundColor: @color-info;
  @BorderBottomColor: @color-info;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @color-info;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-info;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @color-info;
  @BorderTopWidth: @input-border-width;
  @FontSize: @input-font-size;
  @ForegroundColor: @color-info-contrast;
  @Height: @input-height;
  @PaddingBottom: @input-padding-v;
  @PaddingLeft: @input-padding-v;
  @PaddingRight: @input-padding-v;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @Width: 100%;



  .richtextbox-status-mixin();
}

.richtextbox-status-success {
  .global-variables();
  @BackgroundColor: @color-success;
  @BorderBottomColor: @color-success;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @color-success;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-success;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @color-success;
  @BorderTopWidth: @input-border-width;
  @FontSize: @input-font-size;
  @ForegroundColor: @color-success-contrast;
  @Height: @input-height;
  @PaddingBottom: @input-padding-v;
  @PaddingLeft: @input-padding-v;
  @PaddingRight: @input-padding-v;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @Width: 100%;



  .richtextbox-status-mixin();
}

.richtextbox-status-warning {
  .global-variables();
  @BackgroundColor: @color-warning;
  @BorderBottomColor: @color-warning;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @color-warning;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-warning;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @color-warning;
  @BorderTopWidth: @input-border-width;
  @FontSize: @input-font-size;
  @ForegroundColor: @color-warning-contrast;
  @Height: @input-height;
  @PaddingBottom: @input-padding-v;
  @PaddingLeft: @input-padding-v;
  @PaddingRight: @input-padding-v;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @Width: 100%;



  .richtextbox-status-mixin();
}

.richtextbox-status-danger {
  .global-variables();
  @BackgroundColor: @color-danger;
  @BorderBottomColor: @color-danger;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @color-danger;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @color-danger;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @color-danger;
  @BorderTopWidth: @input-border-width;
  @FontSize: @input-font-size;
  @ForegroundColor: @color-danger-contrast;
  @Height: @input-height;
  @PaddingBottom: @input-padding-v;
  @PaddingLeft: @input-padding-v;
  @PaddingRight: @input-padding-v;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @Width: 100%;



  .richtextbox-status-mixin();
}

.richtextbox-size-xs {
  .global-variables();
  @BackgroundColor: @input-bg;
  @BorderBottomColor: @input-border-color;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @input-border-color;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @input-border-color;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @input-border-color;
  @BorderTopWidth: @input-border-width;
  @FontSize: @font-xs;
  @ForegroundColor: @input-color;
  @PaddingBottom: @input-padding-v;
  @PaddingLeft: @input-padding-v;
  @PaddingRight: @input-padding-v;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @Height: 20px;
  @Width: 100%;



  .richtextbox-size-mixin();
}

.richtextbox-size-sm {
  .global-variables();
  @BackgroundColor: @input-bg;
  @BorderBottomColor: @input-border-color;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @input-border-color;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @input-border-color;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @input-border-color;
  @BorderTopWidth: @input-border-width;
  @FontSize: @font-sm;
  @ForegroundColor: @input-color;
  @PaddingBottom: @input-padding-v;
  @PaddingLeft: @input-padding-v;
  @PaddingRight: @input-padding-v;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @Height: 25px;
  @Width: 100%;



  .richtextbox-size-mixin();
}

.richtextbox-size-med {
  .global-variables();
  @BackgroundColor: @input-bg;
  @BorderBottomColor: @input-border-color;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @input-border-color;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @input-border-color;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @input-border-color;
  @BorderTopWidth: @input-border-width;
  @FontSize: @font-med;
  @ForegroundColor: @input-color;
  @Height: @input-height;
  @PaddingBottom: @input-padding-v;
  @PaddingLeft: @input-padding-v;
  @PaddingRight: @input-padding-v;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @Width: 100%;



  .richtextbox-size-mixin();
}

.richtextbox-size-lg {
  .global-variables();
  @BackgroundColor: @input-bg;
  @BorderBottomColor: @input-border-color;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @input-border-color;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @input-border-color;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @input-border-color;
  @BorderTopWidth: @input-border-width;
  @FontSize: @font-lg;
  @ForegroundColor: @input-color;
  @PaddingBottom: @input-padding-v;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @Height: 50px;
  @PaddingLeft: 10px;
  @PaddingRight: 10px;
  @Width: 100%;



  .richtextbox-size-mixin();
}

.richtextbox-size-xl {
  .global-variables();
  @BackgroundColor: @input-bg;
  @BorderBottomColor: @input-border-color;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @input-border-color;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @input-border-color;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @input-border-color;
  @BorderTopWidth: @input-border-width;
  @FontSize: @font-xl;
  @ForegroundColor: @input-color;
  @PaddingBottom: @input-padding-v;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @Height: 75px;
  @PaddingLeft: 20px;
  @PaddingRight: 20px;
  @Width: 100%;



  .richtextbox-size-mixin();
}



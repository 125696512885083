@import "../../../@theming/mixings/fonts.less";
@import "../../../@theming/mixings/dimesions.less";

.tabcontainer-mixin () {
  // Fonts
  .font-mixin();
  // Dimensions
  .component-dimesions();

  .p-tabview {
    .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
  }
}

.tabcontainer-status-mixin () {
  .tabcontainer-mixin();
}


.tabheader-status-mixin() {

  .p-tabview .p-tabview-nav {
    background: @BackgroundColor;
  }

  .p-tabview .p-tabview-nav li {
    margin-right: 0;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-top: @BorderTopWidth @BorderTopStyle @BorderTopColor !important;
    border-bottom: @BorderBottomWidth @BorderBottomStyle @BorderBottomColor !important;
    border-left: @BorderLeftWidth @BorderLeftStyle @BorderLeftColor !important;
    border-right: @BorderRightWidth @BorderRightStyle @BorderRightColor !important;
    background: @BackgroundColor;
    color: @ForegroundColor;
    padding: 1rem;
    font-weight: 600;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: box-shadow 0.2s;
    margin: 0 0 -2px 0;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
  }

  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: @HoverBackgroundColor;
    border-color: #6c757d;
    color: @HoverColor;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: @ActiveBackgroundColor;
    border-color: #2196F3;
    color: @ActiveColor;
  }

  .p-tabview .p-tabview-left-icon {
    margin-right: 0.5rem;
  }

  .p-tabview .p-tabview-right-icon {
    margin-left: 0.5rem;
  }

  .p-tabview .p-tabview-close {
    margin-left: 0.5rem;
  }
}

.tabcontent-status-mixin() {
  
}

.tabpage-status-mixin() {
  .p-tabview .p-tabview-panels {
    background: @BackgroundColor;
    padding: 1rem;
    border: 0 none;
    color: @ForegroundColor;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .p-tabview .p-tabview-panels [zappLabel] {
    color: @ForegroundColor !important;
  }
}

.p-tabview-nav-container {
  position: relative;
}

.p-tabview-nav {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
  flex-wrap: wrap;
}

.p-tabview-nav-link {
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  position: relative;
  text-decoration: none;
  overflow: hidden;
}

.p-tabview-ink-bar {
  display: none;
  z-index: 1;
}

.p-tabview-nav-link:focus {
  z-index: 1;
}

.p-tabview-title {
  line-height: 1;
}

.p-tabview-close {
  z-index: 1;
}

@import "../../../@theming/mixings/guards.less";
@import "../../../@theming/mixings/shorthands.less";

.calendar-status-mixin() {

  .fc.fc-theme-standard {

    .fc-header-toolbar {
      .background-color(@HeaderBackgroundColor);
      .color(@HeaderColor);
      .font-size(@HeaderFontSize);
      height: @HeaderHeight;


      .fc-button {
        .background-color(@ButtonsBackgroundColor);
        .color(@ButtonsColor);
        border: none;
        border-radius: @ButtonsRadius;
      }

      .fc-dayGridMonth-button:not(.fc-button-active),
      .fc-timeGridWeek-button:not(.fc-button-active),
      .fc-timeGridDay-button:not(.fc-button-active) {
        .background-color(@ButtonsColor);
        .color(@ButtonsBackgroundColor);
      }

      .fc-dayGridMonth-button.fc-button-active,
      .fc-timeGridWeek-button.fc-button-active,
      .fc-timeGridDay-button.fc-button-active {
        .background-color(@ButtonsBackgroundColor);
        .color(@ButtonsColor);
      }

      .fc-button-group {
        .fc-button {
          border-radius: 0;
        }

        .fc-button:first-child {
          border-top-left-radius: @ButtonsRadius;
          border-bottom-left-radius: @ButtonsRadius;
        }

        .fc-button:last-child {
          border-top-right-radius: @ButtonsRadius;
          border-bottom-right-radius: @ButtonsRadius;
        }
      }
    }
  }
}

@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';
@import "../../../@theming/mixings/guards.less";
@import "../../../@theming/mixings/shorthands.less";

.flex {
    display: flex;
}

.grid-mixin () {
  // Fonts
  .font-mixin();

  // Dimensions
  .component-dimesions();
}

.grid-status-mixin () {
  .p-datatable-wrapper {
    overflow-x: auto;
  }

  &.zapptable-hover-effect {
  }

  &.zapptable-alternate-row {
  }

  .p-datatable-tfoot {
    [zappbutton] {
      margin: 0rem 0rem 1rem 1rem !important;
    }
  }

  .zappdev-no-total-records-number {
    .p-paginator-current {
      display: none !important;
    }
  }

  .p-datatable-numbering {
    color: @RowColor;
  }

  .p-datatable .p-datatable-thead > tr > th {
    > [zappLabel] {
      color: @HeaderColor !important;
    }

    .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
    .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
    .color(@HeaderColor);
    .background-color(@HeaderBackgroundColor);
  }

  .p-datatable .p-datatable-tbody > tr {
    .background-color(@RowBackground);
    .color(@RowColor);
  }
  //  color: @ForegroundColor;
  //  background-color: @BackgroundColor;
  th {
    .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);

    &.numbering {
      width: 20px;
    }

    &.grid-remove-cell {
      width: 40px;
    }

    &.grid-multi-remove-cell {
      width: 40px;
    }
  }

  td {
    .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);

    [zapptextbox] {
      margin-bottom: 0px !important;
    }

    &.grid-remove-cell {
      width: 40px;
      min-width: 40px;
      padding: 0px !important;
      text-align: center !important;

      [zappButton] {
        padding: 0px !important;
        margin: 0px !important;
        // background-color: transparent !important;
        border: none;
        // color: @color-danger;
        // width: 24px !important;
        min-width: 24px !important;

        &:focus,
        &:not(:disabled):not(.disabled):active:focus {
          box-shadow: none;
        }
        // zappicon {
        //   color: @color-danger !important;
        // }
      }
    }
  }
}

.additemlabel-status-mixin() {
  button.p-button {
    .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);

    i {
      .color(@ForegroundColor);
      .opacity(@IconOpacity);
      .font-size(@FontSize);
    }
  }
}

.removeitemlabel-status-mixin() {
  i.clickable.round {
    .color(@ForegroundColor);
    .opacity(@IconOpacity);
  }
}

.pager-status-mixin() {
  .p-paginator-pages .p-paginator-page {
    .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
    .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);
    .color(@ForegroundColor);
    .background-color(@BackgroundColor);
    .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
    .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
    .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
    .font(@FontDecoration, @FontFamily, @FontStyle, @FontSize, @FontWeight);

    &:hover {
      .background-color(@HoverBackgroundColor);
      .color(@HoverColor);
    }

    &.p-highlight {
      .background-color(@ActiveBackgroundColor);
      .color(@ActiveColor);
      .border-color(@ActiveBorderColor, @ActiveBorderColor, @ActiveBorderColor, @ActiveBorderColor);
    }
  }
}

.cell-status-mixin() {
  & > p-table > .p-datatable > .p-datatable-wrapper > table {

    & > thead.p-datatable-thead > tr > th,
    & > tbody.p-element > tr > td,
    & > tfoot.p-datatable-tfoot > tr > td {
      .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);
      .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
      .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
      .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
      .background-color(@BackgroundColor);
    }
  }
}

@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

.icon-mixin () {

  i {
    .color(@ForegroundColor);
    .background-color(@BackgroundColor);
    .font-size(@FontSize);
    .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
    .padding(@Padding, @Padding, @Padding, @Padding);
    .opacity(@Opacity);

    &:hover {
      opacity: 1;
    }

    &.round {
      border-radius: 50%;
      .background-color(@RoundBackgroundColor);
      .color(@RoundForegroundColor);
    }

    &.clickable {
        cursor: pointer;
    }
  }
}

.icon-status-mixin () {
  .icon-mixin();
}

.icon-size-mixin () {
  .icon-mixin();
}

@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';
@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

.menu-mixin () {

}

.menucontrol-status-mixin() {
  .p-menubar {
    padding: 0;
    background: @navbar-bg-color;
    color: @navbar-color;
    border: 0 none;
    border-radius: 0px;

    ul.p-menubar-root-list {
      height: @Height;
    }
  }

  .p-tieredmenu {
    background: @navbar-bg-color;
  }

  .p-menubar .p-menuitem-link {
    .padding(@ItemPaddingTop, @ItemPaddingRight, @ItemPaddingBottom, @ItemPaddingLeft);
    color: #212529;
    border-radius: 0;
    transition: box-shadow 0.15s;
    user-select: none;
  }

  ul.p-menubar-root-list>li.p-menuitem {
    &.header {
      .background-color(@navbar-brand-bg-color);
      .font-size(@navbar-brand-font-size);
      .font-weight(@navbar-brand-font-weight);

      >.p-menuitem-content {
        .p-menuitem-text {
          .color(@navbar-brand-color);
        }
      }
    }
  }

  .p-menubar .p-menuitem-link .p-menuitem-text, .p-tieredmenu .p-menuitem-link .p-menuitem-text {
    color: @navbar-color;
    white-space: nowrap;
  }

  .p-menubar .p-menuitem-link .p-menuitem-icon {
    color: @navbar-color;
    margin-right: 0.5rem;
  }

  .p-menubar .p-menuitem-link .p-submenu-icon, .p-tieredmenu .p-menuitem-link .p-submenu-icon {
    color: @navbar-color;
  }

  .p-menubar .p-menuitem-link:not(.p-disabled):hover, .p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
    background: @navbar-item-hover-bg;
  }

  .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: @navbar-item-hover-color !important; // override active item color
  }

  .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: @navbar-item-hover-color;
  }

  .p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: @navbar-item-hover-color;
  }

  .p-menubar .p-menuitem-link:focus, .p-tieredmenu .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link, .p-tieredmenu .p-menuitem > .p-menuitem-link {
    .padding(@ItemPaddingTop, @ItemPaddingRight, @ItemPaddingBottom, @ItemPaddingLeft);
    color: @navbar-color;
    border-radius: @navbar-border-radius;
    transition: box-shadow 0.15s;
    user-select: none;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text, .p-tieredmenu .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: @navbar-color;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: @navbar-color;
    margin-right: 0.5rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon, .p-tieredmenu .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: @navbar-color;
    margin-left: 0.5rem;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover, .p-tieredmenu .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: @navbar-item-hover-bg;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: @navbar-item-hover-color;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: @navbar-item-hover-color;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: @navbar-item-hover-color;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus, .p-tieredmenu .p-menuitem > .p-menuitem-link:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
  }

  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover,
  .p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: @navbar-item-hover-bg;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: @navbar-item-hover-color;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: @navbar-item-hover-color;
  }

  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: @navbar-item-hover-color;
  }

  .p-menubar .p-submenu-list, .p-tieredmenu .p-submenu-list {
    padding: 0.5rem 0;
    background: @navbar-bg-color;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: none;
    width: unset;
    min-width: 12.5rem;
  }

  .p-menubar .p-submenu-list .p-menu-separator {
    border-top: 1px solid #dee2e6;
    margin: 0.5rem 0;
  }

  .p-menubar .p-submenu-list .p-submenu-icon {
    font-size: 0.875rem;
  }

  .p-menubar .p-submenu-list .p-menuitem {
    margin: 0;
  }

  .p-menubar .p-submenu-list .p-menuitem:last-child {
    margin: 0;
  }

  .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
    background: @navbar-item-active-bg;
  }

  .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
    color: @navbar-item-active-color;
  }

  .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon, .p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
    color: @navbar-item-active-color;
  }

  @media screen and (max-width: 960px) {
    .p-menubar {
      position: relative;
    }

    .p-menubar .p-menubar-button, .p-tieredmenu .p-menubar-button {
      display: flex;
      width: 2rem;
      height: 2rem;
      color: @navbar-color;
      border-radius: 50%;
      transition: box-shadow 0.15s;
    }

    .p-menubar .p-menubar-button:hover, .p-tieredmenu .p-menubar-button:hover {
      color: rgba(0, 0, 0, 0.7);
      background: @navbar-item-hover-bg;
    }

    .p-menubar .p-menubar-button:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }

    .p-menubar .p-menubar-root-list {
      position: absolute;
      display: none;
      padding: 0.5rem 0;
      background: @navbar-bg-color;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: none;
      width: 100%;
    }

    .p-menubar .p-menubar-root-list .p-menu-separator {
      border-top: 1px solid #dee2e6;
      margin: 0.5rem 0;
    }

    .p-menubar .p-menubar-root-list .p-submenu-icon {
      font-size: 0.875rem;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem {
      width: 100%;
      position: static;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link, .p-tieredmenu .p-menuitem > .p-menuitem-link {
      padding: 0.75rem 1rem;
      color: @navbar-color;
      border-radius: 0;
      transition: box-shadow 0.15s;
      user-select: none;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text, .p-tieredmenu .p-menuitem > .p-menuitem-link .p-menuitem-text {
      color: @navbar-color;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon, .p-tieredmenu .p-menuitem > .p-menuitem-link .p-menuitem-icon {
      color: @navbar-color;
      margin-right: 0.5rem;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon, .p-tieredmenu .p-menuitem > .p-menuitem-link .p-submenu-icon {
      color: @navbar-color;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover, .p-tieredmenu .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
      background: @navbar-item-hover-bg;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
      color: @navbar-item-hover-color;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
      color: @navbar-item-hover-color;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
      color: @navbar-item-hover-color;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
    }

    .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
      margin-left: auto;
      transition: transform 0.15s;
    }

    .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
      transform: rotate(-180deg);
    }

    .p-menubar .p-menubar-root-list .p-submenu-list {
      width: 100%;
      position: static;
      box-shadow: none;
      border: 0 none;
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
      transition: transform 0.15s;
      transform: rotate(90deg);
    }

    .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
      transform: rotate(-90deg);
    }

    .p-menubar .p-menubar-root-list .p-menuitem {
      width: 100%;
      position: static;
    }

    .p-menubar .p-menubar-root-list ul li a {
      padding-left: 2.25rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li a {
      padding-left: 3.75rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li a {
      padding-left: 5.25rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
      padding-left: 6.75rem;
    }

    .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
      padding-left: 8.25rem;
    }

    .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
      display: flex;
      flex-direction: column;
      top: 100%;
      left: 0;
      height: unset;
      z-index: 1;
    }
  }
}

@import '../../@framework/components/Checkbox/checkbox.component.less';
.checkbox-global-variables() {
                  .global-variables();
                  @BorderColor: @input-border-color;

                }

                .zapp-checkbox  {
                  .checkbox-global-variables();
                  
                    @BorderColor: @color-default;

                  
                  .checkbox-status-mixin();
}

.checkbox-status-default {
  .global-variables();
  @BorderColor: @color-default;



  .checkbox-status-mixin();
}

.checkbox-status-primary {
  .global-variables();
  @BorderColor: @color-primary;



  .checkbox-status-mixin();
}

.checkbox-status-info {
  .global-variables();
  @BorderColor: @color-info;



  .checkbox-status-mixin();
}

.checkbox-status-success {
  .global-variables();
  @BorderColor: @color-success;



  .checkbox-status-mixin();
}

.checkbox-status-warning {
  .global-variables();
  @BorderColor: @color-warning;



  .checkbox-status-mixin();
}

.checkbox-status-danger {
  .global-variables();
  @BorderColor: @color-danger;



  .checkbox-status-mixin();
}








.font-mixin (@variables) {
  // Fonts
  font-size: @variables[FontSize];
  font-weight: @variables[FontWeight];
  text-decoration: @variables[FontDecoration];
  font-family: @variables[FontFamily];
  font-style: @variables[FontStyle];
}

.font-mixin () {
  // Fonts
  font-size: @FontSize;
  font-weight: @FontWeight;
  text-decoration: @FontDecoration;
  font-family: @FontFamily;
  font-style: @FontStyle;
  font-weight: @FontWeight;
}

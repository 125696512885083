
@import '../../@framework/components/Modal/modal.component.less';
.modal-global-variables() {
                  .global-variables();
                  @BackgroundColor: @bg-color;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;

                }

                .zapp-modal  {
                  .modal-global-variables();
                  
                  
                  
                  .modal-status-mixin();
}

.modal-status-default {
  .global-variables();



  .modal-status-mixin();
}

.modal-status-primary {
  .global-variables();



  .modal-status-mixin();
}

.modal-status-info {
  .global-variables();



  .modal-status-mixin();
}

.modal-status-success {
  .global-variables();



  .modal-status-mixin();
}

.modal-status-warning {
  .global-variables();



  .modal-status-mixin();
}

.modal-status-danger {
  .global-variables();



  .modal-status-mixin();
}








.p-dialog-mask {
  .modal-global-variables();
  .modal-overlay();
}

.p-dialog {
  .modal-global-variables();
  .confirm-dialog();
}

.p-confirm-popup {
  .modal-global-variables();
  .confirm-popup-message();
}
.modal-global-variables() {
                  .global-variables();
                  @BackgroundColor: @bg-color;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;

                }

                .zapp-modal  {
                  .modal-global-variables();
                  
                  
                  
                  .modal-status-mixin();
}

.modal-status-default {
  .global-variables();



  .modal-status-mixin();
}

.modal-status-primary {
  .global-variables();



  .modal-status-mixin();
}

.modal-status-info {
  .global-variables();



  .modal-status-mixin();
}

.modal-status-success {
  .global-variables();



  .modal-status-mixin();
}

.modal-status-warning {
  .global-variables();



  .modal-status-mixin();
}

.modal-status-danger {
  .global-variables();



  .modal-status-mixin();
}







.modalcontent-global-variables() {
                  .global-variables();
                  @BackgroundColor: @containers-color;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;

                }

                .zapp-modalcontent .modalcontent  {
                  .modalcontent-global-variables();
                  
                  
                  
                  .modalcontent-status-mixin();
}

.modal-status-default .modalcontent {
  .global-variables();
  @BackgroundColor: @containers-color;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;



  .modalcontent-status-mixin();
}

.modal-status-primary .modalcontent {
  .global-variables();
  @BackgroundColor: @containers-color;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;



  .modalcontent-status-mixin();
}

.modal-status-info .modalcontent {
  .global-variables();
  @BackgroundColor: @containers-color;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;



  .modalcontent-status-mixin();
}

.modal-status-success .modalcontent {
  .global-variables();
  @BackgroundColor: @containers-color;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;



  .modalcontent-status-mixin();
}

.modal-status-warning .modalcontent {
  .global-variables();
  @BackgroundColor: @containers-color;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;



  .modalcontent-status-mixin();
}

.modal-status-danger .modalcontent {
  .global-variables();
  @BackgroundColor: @containers-color;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @Shadow: @default-shadow;



  .modalcontent-status-mixin();
}







.p-dialog-header-global-variables() {
                  .global-variables();
                  @BackgroundColor: @bg-color;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @ForegroundColor: @font-color;
  @PaddingBottom: 0.75rem;
  @PaddingLeft: 1.5rem;
  @PaddingRight: 1.5rem;
  @PaddingTop: 0.75rem;

                }

                .zapp-p-dialog-header .p-dialog-header  {
                  .p-dialog-header-global-variables();
                  
                  
                  
                  .p-dialog-header-status-mixin();
}

.modal-status-default .p-dialog-header {
  .global-variables();
  @BackgroundColor: @color-default;
  @CloseModalIconColor: @color-default-contrast;
  @ForegroundColor: @color-default-contrast;



  .p-dialog-header-status-mixin();
}

.modal-status-primary .p-dialog-header {
  .global-variables();
  @BackgroundColor: @color-primary;
  @CloseModalIconColor: @color-primary-contrast;
  @ForegroundColor: @color-primary-contrast;



  .p-dialog-header-status-mixin();
}

.modal-status-info .p-dialog-header {
  .global-variables();
  @BackgroundColor: @color-info;
  @CloseModalIconColor: @color-info-contrast;
  @ForegroundColor: @color-info-contrast;



  .p-dialog-header-status-mixin();
}

.modal-status-success .p-dialog-header {
  .global-variables();
  @BackgroundColor: @color-success;
  @CloseModalIconColor: @color-success-contrast;
  @ForegroundColor: @color-success-contrast;



  .p-dialog-header-status-mixin();
}

.modal-status-warning .p-dialog-header {
  .global-variables();
  @BackgroundColor: @color-warning;
  @CloseModalIconColor: @color-warning-contrast;
  @ForegroundColor: @color-warning-contrast;



  .p-dialog-header-status-mixin();
}

.modal-status-danger .p-dialog-header {
  .global-variables();
  @BackgroundColor: @color-danger;
  @CloseModalIconColor: @color-danger-contrast;
  @ForegroundColor: @color-danger-contrast;



  .p-dialog-header-status-mixin();
}







.p-dialog-content-global-variables() {
                  .global-variables();
                  @BackgroundColor: @bg-color;

                }

                .zapp-p-dialog-content .p-dialog-content  {
                  .p-dialog-content-global-variables();
                  
                  
                  
                  .p-dialog-content-status-mixin();
}

.modal-status-default .p-dialog-content {
  .global-variables();



  .p-dialog-content-status-mixin();
}

.modal-status-primary .p-dialog-content {
  .global-variables();



  .p-dialog-content-status-mixin();
}

.modal-status-info .p-dialog-content {
  .global-variables();



  .p-dialog-content-status-mixin();
}

.modal-status-success .p-dialog-content {
  .global-variables();



  .p-dialog-content-status-mixin();
}

.modal-status-warning .p-dialog-content {
  .global-variables();



  .p-dialog-content-status-mixin();
}

.modal-status-danger .p-dialog-content {
  .global-variables();



  .p-dialog-content-status-mixin();
}







.p-dialog-footer-global-variables() {
                  .global-variables();
                  @BackgroundColor: @bg-color;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @PaddingBottom: 1rem;
  @PaddingLeft: 1rem;
  @PaddingRight: 1rem;
  @PaddingTop: 1rem;

                }

                .zapp-p-dialog-footer .p-dialog-footer  {
                  .p-dialog-footer-global-variables();
                  
                  
                  
                  .p-dialog-footer-status-mixin();
}

.modal-status-default .p-dialog-footer {
  .global-variables();



  .p-dialog-footer-status-mixin();
}

.modal-status-primary .p-dialog-footer {
  .global-variables();



  .p-dialog-footer-status-mixin();
}

.modal-status-info .p-dialog-footer {
  .global-variables();



  .p-dialog-footer-status-mixin();
}

.modal-status-success .p-dialog-footer {
  .global-variables();



  .p-dialog-footer-status-mixin();
}

.modal-status-warning .p-dialog-footer {
  .global-variables();



  .p-dialog-footer-status-mixin();
}

.modal-status-danger .p-dialog-footer {
  .global-variables();



  .p-dialog-footer-status-mixin();
}








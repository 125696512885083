
@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';

.progressbar-mixin () {

  width: 500px;
  display: block;
  // Fonts
  .font-mixin();

  // Dimensions
  .component-dimesions();

  .progressbar-container {
    //height:@variables[HeightValue];
    width:100%;
    // background:@variables[ProgressBarBackgroundColor];

  }

  .progress-bar {
    height:@Height;
    background:@BackgroundColor;
    text-align:center;
    line-height:@Height;
  }

  .percentage {
    color:@ForegroundColor;
    font-size: @FontSize;
    line-height:@Height;
  }
}

.progressbar-status-mixin () {
  .progressbar-container {
    background:@ProgressBarBackgroundColor;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    border-radius: @BorderRadiusTopLeft @BorderRadiusTopRight @BorderRadiusBottomRight @BorderRadiusBottomLeft;
    margin-bottom: 5px;
  }

  .progress-bar {
    background:@BackgroundColor;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    transition: width .6s ease;
  }

  .percentage {
    color:@ForegroundColor;
    white-space: nowrap;
  }
}

.progressbar-size-mixin () {
  .progressbar-container {
    height: @Height !important;
  }

  .progress-bar {
    height:@Height;
    line-height:@Height;
  }

  .percentage {
    font-size: @FontSize;
    line-height:@Height;
    white-space: nowrap;
  }
}

@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

.optionbutton-mixin () {
  display: block; //necessary for the required indicator

  .p-radiobutton .p-radiobutton-box.p-highlight {
    .border-color(@BorderColor);
    .background-color(@BorderColor);
  }

  &.horizontal-radio-button {
    > div {
      display: flex;

      > div {
          margin-right: 1rem;
      }
    }
  }
}

.optionbutton-status-mixin () {
  .optionbutton-mixin();
}

.optionbutton-size-mixin() {
  .optionbutton-mixin();
}

@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

.hyperlink-mixin () {
  .background-color(@BackgroundColor);
  .color(@ForegroundColor);

  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
    .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);
    

  .font(@FontDecoration, @FontFamily, @FontStyle, @FontSize, @FontWeight);

  &:hover {
    .color(@HoverColor);
  }
}

.hyperlink-status-mixin () {
  .hyperlink-mixin();
}

.hyperlink-size-mixin () {
  .hyperlink-mixin();
}

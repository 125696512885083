@import './app/@theming/themes/default.less';
@import './app/@theming/global/components.less';
@import './primeng.min.css';
@import './lara-light-blue.theme.css';
@import './primeflex.min.css';

// Theme LESS Code & User LESS Code
@import "./assets/libraries/primeicons/primeicons.css";



body
{
    background-color: var(--gray-100) !important; 
}

html { 
    font-size: 14px; 
}

.p-input-icon-right > i:nth-last-of-type(2) {
  right: 2rem;
  color: #6c757d;
}

.p-input-icon-right > i:nth-last-of-type(3) {
  right: 3.5rem;
}

.list-status-default table.dataTable,
.list-status-default table.dataTable.display {
    text-align: left;
}

.master-page-layout {
  display: block;
  padding: 0px;
  margin: 0px 2rem;
}

.content-width {
    max-width: 740px;
    margin: 0 auto;
}

&.required {
    position: relative;
    
    &::after {
      content: ".";
      position: absolute;
      top: -1.25rem;
      right: 0.25rem;
      line-height: 0rem;
      font-size: 3.5rem;
      color: red;
    }
    
    &.zapp-textarea::after {
        top: -0.75rem;
    }
    
    &.zapp-richtextbox::after {
        top: -0.5rem;
    }
}

.master-page-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 25px;
    line-height: 25px;
    z-index: 10;
    background-color: @containers-color;//#fafbfc;
}

.p-container {
    padding: 1.5rem 3rem 3rem 3rem;
    
    &.fixed-width {
        width: 30rem;
    }
}

.with-right-element {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
}

.container {
    margin-right: auto;
    margin-left: auto;
}

.p-panel-header {
    .push-right {
        margin-left: auto;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  
 .container {
     width: 540px;
 }
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
 .container {
     width: 720px;
 }
}
 
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
 .container {
     width: 960px;
 }
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    .container {
     width: 1140px;
 }
}
:root {
  /* Light */
  /* colors */
  --blue-100: #fcebfe;
  --blue-200: #bddafe;
  --blue-300: #91c3fc;
  --blue-400: #60a5fa;
  --blue-50: #f2f7ff;
  --blue-500: #3479e8;
  --blue-600: #2362ea;
  --blue-700: #1d4fd7;
  --blue-800: #1d3fae;
  --blue-900: #1e3b8a;
  --gray-100: #f4f4f5;
  --gray-200: #e4e4e7;
  --gray-300: #d4d4d8;
  --gray-400: #a1a1aa;
  --gray-50: #fdfdfd;
  --gray-500: #71717a;
  --gray-600: #52525b;
  --gray-700: #3f3f46;
  --gray-800: #27272a;
  --gray-900: #18181b;
  --orange-100: #ffedd6;
  --orange-200: #fed6a9;
  --orange-300: #fcb972;
  --orange-400: #fb923c;
  --orange-50: #fff5ea;
  --orange-500: #f67316;
  --orange-600: #e8590c;
  --orange-700: #bf3f0c;
  --orange-800: #9b3b12;
  --orange-900: #7c2c11;
}

.test-color-vars 
{
    color: var(--orange-900);
}

.chart-max-height-365 > zapp-chart > p-chart > div > canvas
{
    max-height: 365px;
}

.professional-liked-areas-container
{
    padding: 10px 16px;
    border-radius: 56px;
    background-color: var(--gray-50);
    border: 1px solid var(--gray-300);
    margin-bottom: 16px;
    
    zappdevrepeatercontrol
    {
        display: flex;
        justify-content: space-evenly;
    }
    
    .like-counter
    {
        font-size: 18px;
        font-weight: bold;
        color: var(--gray-700);
        margin-right: 8px;
    }
    
    .area-emoji
    {
        font-size: 20px;
    }
}

.profession-group
{
    position: relative;
}

.professional-heading
{
    margin-bottom: 12px;
}

.company-directory-heading
{
    position: relative;
    border-radius: 0 0 32px 32px;
    overflow: hidden;
    box-shadow: 0 6px 12px 0 rgba(0,0,0, 0.3);
    
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.85) 100%);
    }
    
    > div.text-info
    {
        position: absolute;
        bottom: 32px;
        left: 32px;
        display:flex;
        flex-direction: column;
        gap: 10px;
    }
}

.company-directory-container
{
    margin: 30px 30px 0;
    padding-bottom: 24px;
}

.company-directory-filters
{
    display: flex;
    gap: 16px;
    overflow-x: scroll;
    margin-bottom: 12px;
    
    label
    {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 4px;
        color: var(--gray-500);
    }
    
    label.selected
    {
        border-bottom: 2px solid var(--orange-500);
        color: var(--gray-900);
    }
}

.company-directory-section-header
{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    
    label
    {
        color: var(--gray-900);
        font-size: 16px;
    }
    
    hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid var(--gray-200);
        margin: 1em 0;
        padding: 0;
        flex-grow: 1;
        margin-left: 16px;    
    }
    
}

/* PUBLIC LIST ITEM */

.public-list-item
{
    background-color: var(--gray-50);
    border-radius: 99px 30px 30px 99px;
    margin-bottom: 12px;
    
    &:hover {
        background-color: #e4e4e7;
        cursor: pointer;
    }
    
    > zappicon 
    {
        display: flex;
        
        > i {
            color: var(--gray-500);
            width: 24px;
            height: 24px;
            font-size: 24px;
            margin-right: 20px;
            padding: 0!important;
        }
    }
    
    .image-container
    {
        position: relative;
        
        > zapp-imagebox:first-child {
            position: relative;
        }
        
        .already-reviewed
        {
            position: absolute;
            top: 4px;
            transform: scale(0);
            opacity: 0;
            animation: fadeInScaleUp 0.4s ease forwards;
        }
    }
    
    .text-container
    {
        flex-grow: 1; 
        margin-left: 16px;
        
        label
        {
            line-height:22px;
        }
        
        label.title
        {
            font-size: 16px;
            color: var(--gray-900);
        }
        
        label.subtitle
        {
            color: var(--gray-700);
        }
    }
}

zapp-imagebox
{
    .image-preview
    {
        margin-bottom: 0 !important;
    }
    
    span
    {
        display: flex;
    }
}

.full-rounded {
    border-radius: 999px!important;
    border: 2px solid var(--gray-50);
    box-shadow: 0 4px 7px 0 rgba(0,0,0,0.12);
}

/* END PUBLIC LIST ITEM */

@media screen and (max-width: 1024px)
{
    /* 
     * faces issue where on mobile screens hover effect is applied after
     * touching the button and is removed only when another place is touched 
     */
     
    .review-item-like-btn > button:hover 
    {
        background-color: white!important;
        label, i {
        color: black!important;
        }    
    }
    
    .review-item-like-btn.like-btn-liked > button:hover {
        background-color: black!important;
        label, i {
            color: white!important;
        }
    }
}

/* Small devices (landscape phones, 576px and up) */
@media screen and (max-width: 576px) {  
 .modal-full-width 
    {
        width: 100vw;   
    }
}
 
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media screen and (max-width: 768px) {  
 .modal-full-width 
    {
        width: 100vw;    
    }
}

.dashboard-row-margin-bottom
{
    margin-bottom: 25px;
}

.max-width-lg {
    max-width: 1280px;
}

.color-white {
    color: @color-primary-contrast;
}

zxing-scanner {
 width: 100%;
 height: 100%;
 > video {
     width: auto!important;
     height: 100%!important;
 }
}

.scanner-container {
      position: relative;
      width: 100%;
      height: 100vh; /* Adjust the height as needed */
      overflow: hidden;
    }

.target-rectangle {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Ensure clicks go through to the underlying scanner */
  transition: border-color 0.5s , border-width 0.5s; /* Add a transition for a smooth effect */
  border: 2px solid #3498db; /* Initial border color */
  
  &.scanned {
      border-color: #2ecc71; /* Change border color when QR code is detected */
      border-width: 5px;
   }
}

ngx-scanner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.public-image-background {
    height: 214px;
    position: relative;
    
    img {
        position: relative;
        border-radius: 0!important;
        width: 100%;
        height: 214px;
        object-fit: cover;
        
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
            z-index: 2;
        }
    }
}

.border-radius-md {
    border-radius: 24px;
}

.snapidly-logo-form {
    text-align: center;
    padding-bottom: 16px;
}

.hidden {
    display: none;
}



.z-index-10
{
    z-index: 10;   
}

.public-heading-display {
    font-size: 36px;
}

.public-body-lg
{
    font-size: 20px;
}

.public-body-md
{
    font-size: 16px;
}

.font-weight-500 {
    font-weight: 500;
}

zappicon.public-back-btn 
{
     > i {
        color: var(--gray-500) !important;
        background-color: var(--gray-100) !important;
        box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.12);
        width: 54px;
        height: 54px;
        font-size: 36px;
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 8px !important;
        opacity: 1; 
     }
}

.user-profession-icon-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    gap: 8px;
    
    position: absolute;
    left: 32px;
    top: 23px;
    opacity: 0.4;
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
}

.user-content-background {
    position: relative;
    margin-top: -44px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 24px;
    gap: 38.41px;
    isolation: isolate;
    
    background: linear-gradient(180deg, #EEEEEE 0%, #FFFFFF 100%);
    box-shadow: 0px 3.8408px 115.224px rgba(24, 16, 14, 0.5);
    border-radius: 51.8507px 51.8507px 0px 0px;
}

.user-image-container {
    position: absolute;
    top: -82px;
}

.user-image {
    padding: 0!important;
    border: 8px solid #FFFFFF!important;
    filter: drop-shadow(0px 4px 52px rgba(0, 0, 0, 0.16));
    border-radius: 126px!important;
    background-color: white;
}

.user-details-divider {
    position: absolute;
    top: 27px;
    transform: translate(-50%, 0);
    left: 50%;
    z-index: -1;
    width: 100%;
    border-color: rgba(240, 240, 240, 0.4);
}

.user-employment-container {
}


.user-details-container {
    text-align: center;
    position: relative;
    width: 100%;
}

.user-company-info-container {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 16px;
    gap: 3.68px;
    
    .title {
        font-family: 'New York';
        font-style: normal;
        font-weight: 674;
        font-size: 15.3632px;
        line-height: 18px;
        text-align: center;
        color: rgba(0, 0, 0, 0.7);
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
    }
    
    .subtitle {
        font-family: 'SF Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 9.60199px;
        line-height: 11px;
        text-align: center;
        color: rgba(0, 0, 0, 0.4);
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
    }
}

.user-thank-you-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 28px 0 14px 0;
}

.review-done-container
{
    padding: 24px;
    border: 1px solid var(--gray-300);
    border-radius: 32px;
    background-color: var(--gray-50);
    
    label
    {
        color: var(--gray-600);
        font-size: 17px;
    }
}

.user-thank-you-text {
    font-family: 'Ms Madi';
    font-weight: 400;
    font-size: 34px;
    line-height: 42px;
    color: var(--gray-500);
}

.user-company-logo {
    position: absolute;
    padding: 0!important;
    left: 50%;
    bottom: -20px;
    transform: translate(-50%, 0);
}


.user-employment-info {
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 4px 4px 4px;
    gap: 7.68px;
    background: rgba(240, 240, 240, 1);
    border: 0.960199px solid #DDDDDD;
    border-radius: 999px;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 0;
}

.user-content-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 39px;
    color: var(--gray-800);
}

.user-content-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    color: var(--gray-600);
}

.user-employment-profession {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 32px;
    gap: 7.68px;
    background: #FBFBFB;
    border: 0.960199px solid #DDDDDD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 88.3383px;
    flex: none;
    order: 1;
    flex-grow: 1;
    width: 100%;
    
    .title {
        font-weight: bold;
        font-size: 17px;
        line-height: 18px;
        color: var(--gray-700);
    }
    
    .subtitle {
        font-weight: bold;
        font-size: 11px;
        line-height: 11px;
        color: var(--gray-500);
        align-self: stretch;
    }
}

.submit-review-btn
{
    button
    {
        width: 100%;
        padding: 12px 60px;
        border-radius: 999px;
        box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.15);
        margin-top: 24px;
        background-color: var(--gray-900);
        
        label
        {
            color: var(--gray-50);
            font-size: 24px;
            line-height: 36px ! important;
            font-weight: 700;
        }
    }
}

.review-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 0px 32px;
    gap: 12px;
    background-color: var(--gray-50);
    border-radius: 83px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom:14px;
    justify-content: space-between;
    
    .area-title {
        width: 100%;
        text-align: left;
        color: var(--gray-700);
        font-size: 20px;
        font-weight: 500;
    }
    
    .review-item-like-btn > button {
        padding: 12px 30px;
        background-color: var(--gray-50);
        box-shadow: 0px 3.8408px 11.5224px rgba(0, 0, 0, 0.15);
        border-radius: 54px;
        
        label, i {
            color: var(--gray-900);
            font-size: 20px;
        }
    }
    
    .like-btn-liked > button {
        background-color: var(--gray-900);
        label, i {
            color: var(--gray-50);
        }
        
        &:hover {
             label, i {
                color: var(--gray-50);
            }
            background-color: var(--gray-900);
        }
    }
}

@keyframes fadeInScaleUp {
    to {
        opacity: 1;
        transform: scale(1);
    }
}



@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

.image-preview {
  &.preview {
    p-image {
      img:not(.p-image-preview) {
        display: none;
      }
    }
  }
}

.imagebox-mixin() {

  .image-control-controls {
    display: flex;

    i {
      cursor: pointer;
    }

    .disabled > i {
      cursor: not-allowed;
      opacity: 0.6;
    }

    .disabled > i:hover {
      opacity: 0.6;
    }
  }

  img {
    .opacity(@Opacity);
    .background-color(@BackgroundColor);

    .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
    .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);

    .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
    .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);

    .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);

    .shadow(@Shadow);
  }
}

.imagebox-status-mixin () {
  .imagebox-mixin();

  .p-fileupload {
    .background-color(@BackgroundColor);
    .color(@ForegroundColor);
    .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
    .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
    .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);

    .p-fileupload-buttonbar, .p-fileupload-content {
      background-color: transparent;
    }
    /* button */
    .p-fileupload-choose {
      .background-color(@ButtonBackground);
      .color(@ButtonColor);
      .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);
      .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
      .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
      .border-color(@ButtonBorderColor, @ButtonBorderColor, @ButtonBorderColor, @ButtonBorderColor);
      .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
      .font(@FontDecoration, @FontFamily, @FontStyle, @FontSize, @FontWeight);

      &:hover {
        .background-color(@ButtonHoverBackground);
      }

      .p-button-label {
        .line-height-double(@font-med);
      }
    }
  }

  .single-file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;

    .remove {
      cursor: pointer;
      opacity: @RemoveIconOpacity;
    }
  }
}

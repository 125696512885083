@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

.modal-overlay() {
  
}

.confirm-dialog() {
  
}

.confirm-popup-message() {
  
}

.modalcontent-status-mixin() {
}

.p-dialog-content-status-mixin() {
  .background-color(@BackgroundColor);
  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
  .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);
  .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
  .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
  .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
  .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
}

.p-dialog-header-status-mixin() {
  .background-color(@BackgroundColor);
  .color(@ForegroundColor);
  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
  .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);
  .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
  .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
  .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
  .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);

  p-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
  }

  * {
    .color(@ForegroundColor);
  }

  .p-dialog-title {
    width: 100%;

    .zapp-icon {

      i {
        .color(@CloseModalIconColor);
        .font-size(@CloseModalIconsize);
        .opacity(@CloseModalIconOpacity);
        cursor: pointer;
      }
    }
  }
}

.p-dialog-footer-status-mixin() {
  .background-color(@BackgroundColor);
  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
  .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);
  .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
  .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
  .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
  .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
}

.modal-status-mixin() {
  width:  50vw;
  .background-color(@BackgroundColor);
  .color(@ForegroundColor);
  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
  .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
  .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
  .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
  .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
  .font(@FontDecoration, @FontFamily, @FontStyle, @FontSize, @FontWeight);
}

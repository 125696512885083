@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';


.checkbox-status-mixin () {

  .p-field-checkbox {
    
    label {
      margin-left: 0.5rem;
    }
  }

  .p-checkbox .p-checkbox-box {
    border: @input-border-width solid @input-border-color;
    background: @input-bg;
    color: @input-color;
    border-radius: @default-radius;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  }

  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: @bg-color;
    font-size: @input-font-size;
  }

  .p-checkbox .p-checkbox-box.p-highlight {
    border-color: @BorderColor;
    background: @BorderColor;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: darken(@BorderColor, 5%);
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem lighten(@BorderColor, 10%);
    border-color: @BorderColor;
  }

  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: darken(@BorderColor, 5%);
    background: darken(@BorderColor, 5%);
    color: #ffffff;
  }

  .p-input-filled .p-checkbox .p-checkbox-box {
    background-color: darken(@BorderColor, 5%);
  }

  .p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
    background: #2196F3;
  }

  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    background-color: darken(@BorderColor, 5%);
  }

  .p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background: darken(@BorderColor, 5%);
  }

  .p-checkbox.p-checkbox-disabled .p-checkbox-box.p-highlight {
    border-color: transparent;
    background: fadeout(@BorderColor, 50%);
  }

  .p-checkbox-label {
    margin-left: 0.5rem;
  }
 
}



::ng-deep router-outlet + * {
  display: block;
  // animation: fade 1s;

  @keyframes fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

.bodycontainer-status-mixin() {
  .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
  .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
  .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
  .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
}


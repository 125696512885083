@import '../../@framework/components/DateTimeBox/DateTimeBox.component.less';
.datetimebox-global-variables() {
                  .global-variables();
                  @BackgroundColor: @input-bg;
  @BorderBottomColor: @input-border-color;
  @BorderBottomWidth: @input-border-width;
  @BorderLeftColor: @input-border-color;
  @BorderLeftWidth: @input-border-width;
  @BorderRadiusBottomLeft: @default-radius;
  @BorderRadiusBottomRight: @default-radius;
  @BorderRadiusTopLeft: @default-radius;
  @BorderRadiusTopRight: @default-radius;
  @BorderRightColor: @input-border-color;
  @BorderRightWidth: @input-border-width;
  @BorderTopColor: @input-border-color;
  @BorderTopWidth: @input-border-width;
  @CalendarBackgroundColor: @input-bg;
  @CalendarColor: @input-color;
  @FontSize: @input-font-size;
  @HoverBackgroundColor: @color-default-contrast;
  @HoverColor: @color-default-hover;
  @PaddingBottom: @input-padding-v;
  @PaddingLeft: @input-padding-h;
  @PaddingRight: @input-padding-h;
  @PaddingTop: @input-padding-v;
  @BorderBottomStyle: solid;
  @BorderLeftStyle: solid;
  @BorderRightStyle: solid;
  @BorderTopStyle: solid;
  @ScrollbarSize: 8px;
  @TimeSelectionWidth: 100px;
  @Width: 100%;

                }

                .zapp-datetimebox  {
                  .datetimebox-global-variables();
                  
                  
                  
                  .datetimebox-status-mixin();
}

.datetimebox-status-default {
  .global-variables();
  @BorderBottomColor: @color-default;
  @BorderLeftColor: @color-default;
  @BorderRightColor: @color-default;
  @BorderTopColor: @color-default;



  .datetimebox-status-mixin();
}

.datetimebox-status-primary {
  .global-variables();
  @BorderBottomColor: @color-primary;
  @BorderLeftColor: @color-primary;
  @BorderRightColor: @color-primary;
  @BorderTopColor: @color-primary;



  .datetimebox-status-mixin();
}

.datetimebox-status-info {
  .global-variables();
  @BorderBottomColor: @color-info;
  @BorderLeftColor: @color-info;
  @BorderRightColor: @color-info;
  @BorderTopColor: @color-info;



  .datetimebox-status-mixin();
}

.datetimebox-status-success {
  .global-variables();
  @BorderBottomColor: @color-success;
  @BorderLeftColor: @color-success;
  @BorderRightColor: @color-success;
  @BorderTopColor: @color-success;



  .datetimebox-status-mixin();
}

.datetimebox-status-warning {
  .global-variables();
  @BorderBottomColor: @color-warning;
  @BorderLeftColor: @color-warning;
  @BorderRightColor: @color-warning;
  @BorderTopColor: @color-warning;



  .datetimebox-status-mixin();
}

.datetimebox-status-danger {
  .global-variables();
  @BorderBottomColor: @color-danger;
  @BorderLeftColor: @color-danger;
  @BorderRightColor: @color-danger;
  @BorderTopColor: @color-danger;



  .datetimebox-status-mixin();
}

.datetimebox-size-xs {
  .global-variables();
  @FontSize: @font-xs;



  .datetimebox-size-mixin();
}

.datetimebox-size-sm {
  .global-variables();
  @FontSize: @font-sm;



  .datetimebox-size-mixin();
}

.datetimebox-size-med {
  .global-variables();
  @FontSize: @font-med;



  .datetimebox-size-mixin();
}

.datetimebox-size-lg {
  .global-variables();
  @FontSize: @font-lg;



  .datetimebox-size-mixin();
}

.datetimebox-size-xl {
  .global-variables();
  @FontSize: @font-xl;



  .datetimebox-size-mixin();
}



@import '../../@framework/components/MenuControl/menu-item.less';
              @import '../../@framework/components/MenuControl/MenuControl.component.less';

.menucontrol-global-variables() {
                  .global-variables();
                  @IconColor: @header-color;
  @navbar-bg-color: @header-bg;
  @navbar-border-radius: @default-radius;
  @navbar-brand-bg-color-border: @header-color;
  @navbar-brand-color: @color-primary;
  @navbar-brand-font-size: @font-med;
  @navbar-color: @header-color;
  @navbar-item-active-color: @header-color;
  @navbar-item-hover-color: @header-color;
  @Shadow: @default-shadow;
  @sidenav-hamburger-color: @header-color;
  @Height: 50px;
  @IconMarginRight: 8px;
  @IconSize: 14px;
  @ItemPaddingBottom: 1rem;
  @ItemPaddingLeft: 1rem;
  @ItemPaddingRight: 1rem;
  @ItemPaddingTop: 1rem;
  @MinimumSubmenuWidth: 160px;
  @navbar-border-color: transparent;
  @navbar-brand-bg-color: transparent;
  @navbar-brand-bg-color-focus: rgba(0,0,0,0.1);
  @navbar-brand-bg-color-hover: rgba(0,0,0,0.1);
  @navbar-brand-font-weight: bold;
  @navbar-brand-image-width: 40px;
  @navbar-item-active-bg: rgba(0,0,0,0.1);
  @navbar-item-hover-bg: rgba(0,0,0,0.1);
  @navbar-item-hover-text-decor: unset;
  @RightMenuMarginRight: 0px;
  @sidenav-hamburger-bgcolor: transparent;
  @sidenav-hamburger-close: 15px;
  @sidenav-hamburger-open: 215px;
  @sidenav-hamburger-title-margin: -15px 0 0 55px;
  @sidenav-open-width: 200px;
  @sidenav-toogle-width: 0px;

                }

                .zapp-menucontrol  {
                  .menucontrol-global-variables();
                  
                  
                  
                  .menucontrol-status-mixin();
}

.menucontrol-status-default {
  .global-variables();



  .menucontrol-status-mixin();
}

.menucontrol-status-primary {
  .global-variables();



  .menucontrol-status-mixin();
}

.menucontrol-status-info {
  .global-variables();



  .menucontrol-status-mixin();
}

.menucontrol-status-success {
  .global-variables();



  .menucontrol-status-mixin();
}

.menucontrol-status-warning {
  .global-variables();



  .menucontrol-status-mixin();
}

.menucontrol-status-danger {
  .global-variables();



  .menucontrol-status-mixin();
}








@import "../../../@theming/mixings/guards.less";
@import "../../../@theming/mixings/shorthands.less";

.datetimbox-mixin() {
  .p-calendar {
    .width(@Width);
  }

  input {
    .height(@Height);
    .background-color(@BackgroundColor);
    .color(@ForegroundColor);
    .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
    .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);
    .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
    .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
    .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
    .font(@FontDecoration, @FontFamily, @FontStyle, @FontSize, @FontWeight);
    .line-height-double(@FontSize);
    .shadow(@Shadow);

    &:hover {
      /*.background-color(@HoverBackgroundColor);*/
      .color(@HoverColor);
    }
  }

  &.p-datepicker {
    .background-color(@CalendarBackgroundColor);
    min-width: unset!important;

    table td.p-datepicker table td > span.p-highlight {
      .background-color(@ActiveBackgroundColor);
      .color(@ActiveColor);
    }

    .p-datepicker-today > span {
      .background-color(@TodayBackgroundColor);
      .color(@TodayColor);
    }

    &:not(.p-datepicker-inline) {
      .background-color(@CalendarBackgroundColor);
      .color(@CalendarColor);

      .p-datepicker-header, .p-datepicker-calendar-container {
        .background-color(@CalendarBackgroundColor);
        .color(@CalendarColor);
        .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
      }
    }

    .p-datepicker-calendar {
      .p-element {
        &:hover {
          .background-color-important(@HoverBackgroundColor);
          .color(@HoverColor);
        }
      }
    }

    .p-yearpicker-year.p-highlight, .p-yearpicker-year:hover,
    .p-monthpicker-month.p-highlight, .p-monthpicker-month:hover {
      .background-color-important(@HoverBackgroundColor);
      .color(@HoverColor);
    }
  }
}

.datetimebox-status-mixin () {
  .datetimbox-mixin();
}

.datetimebox-size-mixin () {
  .datetimbox-mixin()
}

@import "../../../@theming/mixings/guards.less";
@import "../../../@theming/mixings/shorthands.less";

:host ::ng-deep {

  .p-multiselect {
    min-width: 100%;
    width: 100%;
  }

  .p-icon-wrapper {
    pointer-events: all;
  }

  .p-dropdown-label:focus-visible {
    outline: none;
  }
}

.dropdownbox-mixin () {
  .p-dropdown, .p-multiselect {
    .width(@Width);
    .background-color(@BackgroundColor);
    .color(@ForegroundColor);
    .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
    /*.padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);*/
    .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
    .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
    .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);

    &:hover {
      .background-color(@BackgroundColor);
      .color(@HoverColor);

      /*.p-inputtext {
        .color(@HoverColor);
      }*/
    }
  }

  .p-inputtext {
    .font-size(@FontSize);
    .background-color(@BackgroundColor);
    .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);
    .color(@ForegroundColor);
    background-color: transparent;
    .line-height-double(@FontSize);

    /*&:hover {
      .color(@HoverColor);
    }*/
  }

  .p-dropdown .p-dropdown-label {
    .font(@FontDecoration, @FontFamily, @FontStyle, @FontSize, @FontWeight);
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    .color(@ForegroundColor);
  }

  // dark theme fix that doesn't work on light theme
  /*.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox-box {
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
    .background-color-important(@BackgroundColor);
  }*/

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
    .color(@ActiveColor);
    .background-color-important(@ActiveBackgroundColor);
  }

  &.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
  .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    .color(@ActiveColor);
    .background-color(@ActiveBackgroundColor);
  }

  &.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    .color(@HoverColor);
    .background-color-important(@HoverBackgroundColor);
  }

  &.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    .color(@ForegroundColor);
  }

  .p-input-filled .p-dropdown {
    .background-color(@ActiveBackgroundColor);
  }

  .p-input-filled .p-dropdown:not(.p-disabled):hover {
    .background-color(@ActiveBackgroundColor);
  }

  .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
    .background-color(@ActiveBackgroundColor);
  }

  .p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label,
  .p-multiselect .p-multiselect-label {
    .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);
    .line-height-double(@FontSize);
  }
}

.dropdownbox-status-mixin () {

  &.p-dropdown-panel {
    .background-color-important(@BackgroundColor);

    .p-dropdown-header {
      .background-color-important(@BackgroundColor);
    }
  }

  .p-multiselect-panel {
    .background-color-important(@BackgroundColor);

    .p-multiselect-header {
      .background-color-important(@BackgroundColor);
    }
  }

  .dropdownbox-mixin();
}

.dropdownbox-size-mixin () {
  .dropdownbox-mixin();
}

/*.dropdownbox-required {
  padding-right: 10px;
  margin-left: 15px;
  background-image: url(../../../@theming/assets/icon-asterisk.png);
  background-repeat: no-repeat;
  background-position-x: ~"calc(100% - 2px)";
  background-size: 5px;
}*/

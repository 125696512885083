@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';
@import '../../../@theming/mixings/borders.less';
@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

zapp-menu {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  z-index: 10;

  p-menubar, .p-menubar {
    flex-grow: 1;

    .p-menubar-root-list, .p-submenu-list {
      z-index: 10;
    }
  }

  p-menubar.p-menu-left {
    float: right;
  }

  .toggle {
    position: absolute;
    right: -2rem;
    top: 0.75rem;
    cursor: pointer;
  }
}

/* Vertical */
.vertical.p-tieredmenu {
  min-width: 15rem;
  height: 100%;
  border-radius: 0;
}

.vertical.p-menubar {
  height: 100%;
  display: inline-block;
}

.vertical.p-menubar .p-menubar-root-list {
  flex-direction: column;
  align-items: start;
}

.vertical.p-menubar .p-menubar-root-list > .p-menuitem-active > .p-menubar-panel {
  left: 100%;
  top: 0;
}

.vertical.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
  margin-left: auto;
}

:host ::ng-deep {
  p-menubarsub {
    width: 100%;
  }

  .p-menubar > a.p-menubar-button {
    width: 3rem;
    height: 3rem
  }
}

.pull-right {
  margin-left: auto;
}

.pull-right:not(.p-hidden) ~ .pull-right {
  margin-left: unset;
}

.pull-right .p-submenu-list {
  left: -6rem !important;

  .p-submenu-list {
    left: -12.5rem !important;
  }
}

.image-menu-item {
  & > a, & > div > a {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    & > span.p-menuitem-icon {
      width: 50px;
      height: 50px;

      &:before {
        content: '';
      }
    }
  }
}

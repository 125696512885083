@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

.label-mixin () {
  .background-color(@BackgroundColor);
  .color(@ForegroundColor);
  .font(@FontDecoration, @FontFamily, @FontStyle, @FontSize, @FontWeight);
  .line-height-double(@FontSize);
  .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
  .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);

  &.hidden {
      display: none;
  }
}

.label-status-mixin () {
  .label-mixin();
}

.label-size-mixin () {
  .label-mixin();
}

.label-badge-mixin() {
    .label-mixin();
}

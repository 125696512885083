@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';

.richtextbox-mixin () {
  display: block; //necessary for the required indicator

  // Fonts
  .font-mixin();
  // Dimensions
  .component-dimesions();
}

.richtextbox-size-mixin () {
    .richtextbox-mixin();
    font-size: @FontSize;
}

.richtextbox-status-mixin () {
    .richtextbox-mixin();
    .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused){
        border-bottom-color:  @BorderTopColor; 
        border-left-color:  @BorderTopColor;
        border-right-color:  @BorderTopColor;
    } 
    .ck.ck-toolbar.ck-toolbar_grouping{
        border-top-color:  @BorderTopColor; 
        border-left-color:  @BorderTopColor;
        border-right-color:  @BorderTopColor;
    }  
}


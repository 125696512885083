@import "../../../@theming/mixings/guards.less";
@import "../../../@theming/mixings/shorthands.less";

.textarea-mixin () {
  display: block; //necessary for the required indicator

  textarea {
    .width(@Width);
    .height(@Height);
    .background-color(@BackgroundColor);
    .color(@ForegroundColor);
    .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
    .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);
    .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
    .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
    .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
    .font(@FontDecoration, @FontFamily, @FontStyle, @FontSize, @FontWeight);
    .line-height-double(@FontSize);
    .shadow(@Shadow);
  }
}

.textarea-status-mixin () {
  .textarea-mixin();
}

.textarea-size-mixin () {
  .textarea-mixin();
}


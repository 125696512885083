@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';

.datasourcegrid-mixin () {
  // Fonts
  .font-mixin();

  // Dimensions
  .component-dimesions();
}

.datasourcegrid-status-mixin () {
    .p-datatable-tfoot {
      [zappbutton] {
        margin: 0rem 0rem 1rem 1rem !important;
      }
    }

    .zappdev-no-total-records-number {
      .p-paginator-current {
        display: none !important;
      }
    }

    //  color: @ForegroundColor;
    //  background-color: @BackgroundColor;
    th {
      &.numbering {
        width: 20px;
      }
      &.datasourcegrid-remove-cell {
        width: 40px;
      }

      &.datasourcegrid-multi-remove-cell {
        width: 40px;
      }
    }

    td {
      [zapptextbox] {
        margin-bottom: 0px !important;
      }

      &.datasourcegrid-remove-cell {
        width: 40px;
        min-width: 40px;
        padding: 0px !important;
        text-align: center !important;

        [zappButton] {
          padding: 0px !important;
          margin: 0px !important;
          // background-color: transparent !important;
          border: none;
          // color: @color-danger;
          // width: 24px !important;
          min-width: 24px !important;
          &:focus,
          &:not(:disabled):not(.disabled):active:focus {
            box-shadow: none;
          }
          // zappicon {
          //   color: @color-danger !important;
          // }
        }
      }
    }

    .p-datatable-numbering {
      font-weight: bold;
      color: @RowColor;
      text-align: center;
    }

    .p-paginator {
      background: @BackgroundColor;
      color: @ForegroundColor;
      border: solid #dee2e6;
      border-width: 0;
      padding: 0.75rem;
      border-radius: 4px;

      .p-paginator-first,
      .p-paginator-prev,
      .p-paginator-next,
      .p-paginator-last {
        background: @BackgroundColor;
        color: @ForegroundColor;
        border: 1px solid #dee2e6;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0 0 0 -1px;
        transition: box-shadow 0.15s;
        border-radius: 0;
      }
      .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
      .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
      .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
      .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
        background: @BackgroundColor;
        color: @ForegroundColor;
        border-color: #dee2e6;
      }
      .p-paginator-first {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .p-paginator-last {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .p-dropdown {
        margin-left: 0.5rem;
        height: 2.357rem;
      }
      .p-dropdown .p-dropdown-label {
        padding-right: 0;
      }
      .p-paginator-current {
        background: @BackgroundColor;
        color: @ForegroundColor;
        border: 1px solid #dee2e6;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0 0 0 -1px;
        padding: 0 0.5rem;
      }
      .p-paginator-pages .p-paginator-page {
        background-color: #ffffff;
        border: 1px solid #dee2e6;
        color: #007bff;
        min-width: 2.357rem;
        height: 2.357rem;
        margin: 0 0 0 -1px;
        transition: box-shadow 0.15s;
        border-radius: 0;
      }
      .p-paginator-pages .p-paginator-page.p-highlight {
        background: #007bff;
        border-color: #007bff;
        color: #ffffff;
      }
      .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
        background: #e9ecef;
        border-color: #dee2e6;
        color: #007bff;
      }
    }

    .p-datatable .p-paginator-top {
      border-width: 1px 0 0 0;
      border-radius: 0;
    }
    .p-datatable .p-paginator-bottom {
      border-width: 1px 0 0 0;
      border-radius: 0;
    }
    .p-datatable .p-datatable-header {
      background: #efefef;
      color: #212529;
      border: solid #dee2e6;
      border-width: 1px 0 0 0;
      padding: 1rem 1rem;
      font-weight: 600;
    }
    .p-datatable .p-datatable-footer {
      background: #efefef;
      color: #212529;
      border: 1px solid #dee2e6;
      border-width: 1px 0 1px 0;
      padding: 1rem 1rem;
      font-weight: 600;
    }
    .p-datatable .p-datatable-thead > tr > th  {
      > [zappLabel] {
        color: @HeaderColor !important;
      }
      text-align: left;
      padding: 1rem 1rem;
      border: 1px solid #dee2e6;
      border-width: 1px 0 2px 0;
      font-weight: 600;
      color: @HeaderColor;
      background: @HeaderBackgroundColor;
      transition: box-shadow 0.15s;
    }
    .p-datatable .p-datatable-tfoot > tr > td {
      text-align: left;
      padding: 1rem 1rem;
      border: 1px solid #dee2e6;
      border-width: 1px 0 1px 0;
      font-weight: 600;
      color: #212529;
      background: #ffffff;
    }
    .p-datatable .p-sortable-column .p-sortable-column-icon {
      color: #6c757d;
      margin-left: 0.5rem;
    }
    .p-datatable .p-sortable-column .p-sortable-column-badge {
      border-radius: 50%;
      height: 1.143rem;
      min-width: 1.143rem;
      line-height: 1.143rem;
      color: #ffffff;
      background: #007bff;
      margin-left: 0.5rem;
    }
    .p-datatable .p-sortable-column:not(.p-highlight):hover {
      background: #e9ecef;
      color: #212529;
    }
    .p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
      color: #6c757d;
    }
    .p-datatable .p-sortable-column.p-highlight {
      background: #ffffff;
      color: #007bff;
    }
    .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
      color: #007bff;
    }
    .p-datatable .p-sortable-column.p-highlight:hover {
      background: #e9ecef;
      color: #007bff;
    }
    .p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
      color: #007bff;
    }
    .p-datatable .p-sortable-column:focus {
      box-shadow: inset 0 0 0 0.15rem rgba(38, 143, 255, 0.5);
      outline: 0 none;
    }
    .p-datatable .p-datatable-tbody > tr {
      background: #ffffff;
      color: #212529;
      transition: box-shadow 0.15s;
      outline-color: rgba(38, 143, 255, 0.5);
    }
    .p-datatable .p-datatable-tbody > tr > td {
      text-align: left;
      border: 1px solid #dee2e6;
      border-width: 1px 0 0 0;
      padding: 1rem 1rem;

      .p-datatable {
        td {
          border: none !important;
          padding: 0.5rem 0.5rem;
        }
        .p-datatable-tfoot {
          [zappbutton] {
            margin: 0rem 0rem 0.5rem 0.5rem !important;
          }
        }
      }
    }
    .p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
      width: 2rem;
      height: 2rem;
      color: #6c757d;
      border: 0 none;
      background: transparent;
      border-radius: 50%;
      transition: box-shadow 0.15s;
    }
    .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
      color: #495057;
      border-color: transparent;
      background: transparent;
    }
    .p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    }
    .p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
      margin-right: 0.5rem;
    }
    .p-datatable .p-datatable-tbody > tr.p-highlight {
      background: #007bff;
      color: #ffffff;
    }
    .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
      box-shadow: inset 0 2px 0 0 #007bff;
    }
    .p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
      box-shadow: inset 0 -2px 0 0 #007bff;
    }
    .p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
      background: #e9ecef;
      color: #212529;
    }
    .p-datatable .p-column-resizer-helper {
      background: #007bff;
    }
    .p-datatable .p-datatable-scrollable-header,
    .p-datatable .p-datatable-scrollable-footer {
      background: #efefef;
    }
    .p-datatable .p-datatable-loading-icon {
      font-size: 2rem;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-header {
      border-width: 1px 1px 0 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-footer {
      border-width: 0 1px 1px 1px;
    }
    .p-datatable.p-datatable-gridlines .p-paginator-top {
      border-width: 0 1px 0 1px;
    }
    .p-datatable.p-datatable-gridlines .p-paginator-bottom {
      border-width: 0 1px 1px 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
      border-width: 1px 1px 2px 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
      border-width: 1px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
      border-width: 1px;
    }
    .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
      background: rgba(0, 0, 0, 0.05);
    }
    .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
      background: #007bff;
      color: #ffffff;
    }
    .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
      color: #ffffff;
    }
    .p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
      color: #ffffff;
    }
    .p-datatable.p-datatable-sm .p-datatable-header {
      padding: 0.5rem 0.5rem;
    }
    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
      padding: 0.5rem 0.5rem;
    }
    .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
      padding: 0.5rem 0.5rem;
    }
    .p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
      padding: 0.5rem 0.5rem;
    }
    .p-datatable.p-datatable-sm .p-datatable-footer {
      padding: 0.5rem 0.5rem;
    }
    .p-datatable.p-datatable-lg .p-datatable-header {
      padding: 1.25rem 1.25rem;
    }
    .p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
      padding: 1.25rem 1.25rem;
    }
    .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
      padding: 1.25rem 1.25rem;
    }
    .p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
      padding: 1.25rem 1.25rem;
    }
    .p-datatable.p-datatable-lg .p-datatable-footer {
      padding: 1.25rem 1.25rem;
    }

    .p-dropdown {
      background: #ffffff;
      border: 1px solid #ced4da;
      transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
      border-radius: 4px;
    }
    .p-dropdown:not(.p-disabled):hover {
      border-color: #ced4da;
    }
    .p-dropdown:not(.p-disabled).p-focus {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
      border-color: #007bff;
    }
    .p-dropdown.p-dropdown-clearable .p-dropdown-label {
      padding-right: 1.75rem;
    }
    .p-dropdown .p-dropdown-label {
      background: transparent;
      border: 0 none;
    }
    .p-dropdown .p-dropdown-label.p-placeholder {
      color: #6c757d;
    }
    .p-dropdown .p-dropdown-label:enabled:focus {
      outline: 0 none;
      box-shadow: none;
    }
    .p-dropdown .p-dropdown-trigger {
      background: transparent;
      color: #495057;
      width: 2.357rem;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .p-dropdown .p-dropdown-clear-icon {
      color: #495057;
      right: 2.357rem;
    }

    .p-dropdown-panel {
      background: #ffffff;
      color: #212529;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      box-shadow: none;
    }
    .p-dropdown-panel .p-dropdown-header {
      padding: 0.75rem 1.5rem;
      border-bottom: 1px solid #dee2e6;
      color: #212529;
      background: #efefef;
      margin: 0;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
      padding-right: 1.75rem;
    }
    .p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
      right: 0.75rem;
      color: #495057;
    }
    .p-dropdown-panel .p-dropdown-items {
      padding: 0.5rem 0;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      margin: 0;
      padding: 0.5rem 1.5rem;
      border: 0 none;
      color: #212529;
      background: transparent;
      transition: box-shadow 0.15s;
      border-radius: 0;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
      color: #ffffff;
      background: #007bff;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
      color: #212529;
      background: #e9ecef;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
      padding: 0.5rem 1.5rem;
      color: #212529;
      background: transparent;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
      margin: 0;
      padding: 0.75rem 1rem;
      color: #212529;
      background: #ffffff;
      font-weight: 600;
    }

    p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
      border-color: #dc3545;
    }

    .p-input-filled .p-dropdown {
      background: #efefef;
    }
    .p-input-filled .p-dropdown:not(.p-disabled):hover {
      background-color: #efefef;
    }
    .p-input-filled .p-dropdown:not(.p-disabled).p-focus {
      background-color: #efefef;
    }

    .p-paginator .p-dropdown {
      margin-left: 0.5rem;
      height: 2.357rem;
    }
    .p-paginator .p-dropdown .p-dropdown-label {
      padding-right: 0;
      padding: 0.5rem 0 0.5rem 0.5rem;
    }
}

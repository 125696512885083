@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';
@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

.body-status-mixin () {
  margin: 0;
  background-color: @BackgroundColor !important;
  color: @ForegroundColor !important;
  font-family: @FontFamily;
}

@import '../../@framework/components/ContainerControl/ContainerControl.component.less';
.containercontrol-global-variables() {
                  .global-variables();
                
                }

                .zapp-containercontrol  {
                  .containercontrol-global-variables();
                  
                  
                  
                  .containercontrol-status-mixin();
}
.containercontrol-global-variables-TopBar() {
                  .global-variables();
                  @BackgroundColor: @color-primary;

                }

                .zapp-containercontrol-TopBar  {
                  .containercontrol-global-variables-TopBar();
                  
                  
                  
                  .containercontrol-status-mixin();
}
.containercontrol-global-variables-FormContent() {
                  .global-variables();
                
                }

                .zapp-containercontrol-FormContent  {
                  .containercontrol-global-variables-FormContent();
                  
                  
                  
                  .containercontrol-status-mixin();
}


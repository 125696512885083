@import '../../../@theming/mixings/fonts.less';
@import '../../../@theming/mixings/dimesions.less';
@import '../../../@theming/mixings/borders.less';
@import '../../../@theming/mixings/guards.less';
@import '../../../@theming/mixings/shorthands.less';

.button-mixin() {
  button {
    .background-color(@BackgroundColor);
    .margin(@MarginTop, @MarginRight, @MarginBottom, @MarginLeft);
    .padding(@PaddingTop, @PaddingRight, @PaddingBottom, @PaddingLeft);
    .border-width(@BorderTopWidth, @BorderRightWidth, @BorderBottomWidth, @BorderLeftWidth);
    .border-style(@BorderTopStyle, @BorderRightStyle, @BorderBottomStyle, @BorderLeftStyle);
    .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
    .border-radius(@BorderRadiusTopLeft, @BorderRadiusTopRight, @BorderRadiusBottomRight, @BorderRadiusBottomLeft);
    .font(@FontDecoration, @FontFamily, @FontStyle, @FontSize, @FontWeight);

    &:enabled:hover {
      .background-color(@HoverBackgroundColor);
      .border-color(@BorderTopColor, @BorderRightColor, @BorderBottomColor, @BorderLeftColor);
    }

    &:disabled {
      cursor: not-allowed;

      > * {
        cursor: not-allowed;
      }
    }

    zapplabel {
      .is-dimension(@FontSize, {
        line-height: calc(2 * @FontSize) !important;
      }

      );
    }

    > label, span, i {
      .color(@ForegroundColor);

      .is-dimension(@FontSize, {
        line-height: calc(2 * @FontSize) !important;
      }

      );
    }

    &:enabled {
      > label, span, i {
        cursor: pointer;
      }
    }

    > label {
      width: 100%;
    }

    span.p-button-label {
      display: none;
    }

    > * + * {
      margin-left: 0.5rem;
    }

    zappicon i {
      padding: 0;
    }
  }

  a {
    .text-decoration-color(@BackgroundColor);

    label {
      cursor: pointer;
      .color(@BackgroundColor);
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.button-status-mixin () {
  .button-mixin();
}

.button-size-mixin () {
  .button-mixin();
}

@import '../../@framework/components/DataSourceGrid/DataSourceGrid.component.less';

.datasourcegrid-global-variables() {
                  .global-variables();
                  @FooterColor: @footer-color;
  @HeaderBackgroundColor: @header-bg;
  @HeaderColor: @header-color;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @row-hover-bg;
  @RowHoverColor: @row-hover-color;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @FooterBackgroundColor: transparent;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;

                }

                .zapp-datasourcegrid  {
                  .datasourcegrid-global-variables();
                  
                  
                  
                  .datasourcegrid-status-mixin();
}

.datasourcegrid-status-default {
  .global-variables();
  @FooterColor: @color-default;
  @HeaderBackgroundColor: @color-default;
  @HeaderColor: @color-default-contrast;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @color-default-hover;
  @RowHoverColor: @color-default-contrast;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @FooterBackgroundColor: transparent;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;



  .datasourcegrid-status-mixin();
}

.datasourcegrid-status-primary {
  .global-variables();
  @FooterColor: @color-primary;
  @HeaderBackgroundColor: @color-primary;
  @HeaderColor: @color-primary-contrast;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @color-primary-hover;
  @RowHoverColor: @color-primary-contrast;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @FooterBackgroundColor: transparent;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;



  .datasourcegrid-status-mixin();
}

.datasourcegrid-status-info {
  .global-variables();
  @FooterColor: @color-info;
  @HeaderBackgroundColor: @color-info;
  @HeaderColor: @color-info-contrast;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @color-info-hover;
  @RowHoverColor: @color-info-contrast;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @FooterBackgroundColor: transparent;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;



  .datasourcegrid-status-mixin();
}

.datasourcegrid-status-success {
  .global-variables();
  @FooterColor: @color-success;
  @HeaderBackgroundColor: @color-success;
  @HeaderColor: @color-success-contrast;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @color-success-hover;
  @RowHoverColor: @color-success-contrast;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @FooterBackgroundColor: transparent;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;



  .datasourcegrid-status-mixin();
}

.datasourcegrid-status-warning {
  .global-variables();
  @FooterColor: @color-warning;
  @HeaderBackgroundColor: @color-warning;
  @HeaderColor: @color-warning-contrast;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @color-warning-hover;
  @RowHoverColor: @color-warning-contrast;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @FooterBackgroundColor: transparent;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;



  .datasourcegrid-status-mixin();
}

.datasourcegrid-status-danger {
  .global-variables();
  @FooterColor: @color-danger;
  @HeaderBackgroundColor: @color-danger;
  @HeaderColor: @color-danger-contrast;
  @RowAlternateBackground: @row-alternative-bg;
  @RowAlternateColor: @row-alternative-color;
  @RowBackground: @row-bg;
  @RowColor: @row-color;
  @RowHoverBackground: @color-danger-hover;
  @RowHoverColor: @color-danger-contrast;
  @Shadow: @default-shadow;
  @BackgroundColor: transparent;
  @BorderCollapse: separate;
  @BorderSpacing: 3px 3px;
  @FooterBackgroundColor: transparent;
  @PagerAlign: center;
  @PagerVerticalMargin: 15px;
  @RowNumberMarginLeft: 40%;
  @RowNumberMarginTop: 4%;



  .datasourcegrid-status-mixin();
}







